/* eslint-disable no-unused-vars */
import TextField from '@common/TextField';
import Typography from '@common/Typography';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import firebase from 'firebase/app';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { regexPhone } from '@core/helpers/regex';
import cx from 'classnames';

import UserIcon from '@heroicons/react/24/outline/UserIcon';
import LockIcon from '@heroicons/react/24/outline/LockClosedIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import { useState } from 'react';
import Button from '@common/Button';
import Link from 'next/link';
import { getLoginInfo, setLogin } from '@helper_auth';

import {
    getCustomerCartId,
    getSigninMethodSocialLogin,
    getToken,
    getTokenOtp,
    mergeCart as mutationMergeCart,
    otpConfig as queryOtpConfig,
    socialLogin,
    requestOtpLogin,
} from '@core_modules/login/services/graphql';
import {
    custDataNameCookie, customerTokenKey, expiredToken, features,
} from '@config';
import Cookies from 'js-cookie';
import OtpInput from '@core_modules/theme/components/header/components/OtpInput';
import { CountdownTimer2 } from '@core_modules/theme/components/header/components/CountdownTimer';

import { getLocalStorage, setLocalStorage } from '@core/helpers/localstorage';
import { assignCompareListToCustomer } from '@core_modules/productcompare/service/graphql';
import { getCartId, setCartId } from '@core/helpers/cartId';
import { useRouter } from 'next/router';
import { getCustomer } from '@core_modules/login/services/graphql/schema';
import { setCookies } from '@core/helpers/cookies';
import { localCompare } from '@core/services/graphql/schema/local';
import { priceVar } from '@core/services/graphql/cache';
import { useQuery, useReactiveVar } from '@apollo/client';

import getConfig from 'next/config';
import { getCookies } from 'cookies-next';

const { publicRuntimeConfig } = getConfig();

const OTP_EXPIRED_TIME_KEY = 'otpExpiredTime';
const OTP_TIMEOUT_TARGET_TIME = 'otpTimeoutTargetTime';

const PopupLogin = (props) => {
    const { t, storeConfig } = props;
    const router = useRouter();
    const isLogin = getLoginInfo();

    // query && mutation
    const [getCustomerToken] = getToken();
    const [getCustomerTokenOtp] = getTokenOtp();
    const [actRequestOtpLogin] = requestOtpLogin();
    const [actSocialLogin] = socialLogin();

    const socialLoginMethod = getSigninMethodSocialLogin();
    const otpConfig = queryOtpConfig();
    const [mergeCart] = mutationMergeCart();
    const [mergeCompareProduct, { client }] = assignCompareListToCustomer();

    const cartData = getCustomerCartId({
        skip: !isLogin,
    });

    const [loginType, setLoginType] = useState('default');
    const [loadingForm, setLoadingForm] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [cusIsLogin, setIsLogin] = React.useState(0);
    const [manySendOtp, setManySendOtp] = React.useState(0);
    const [time, setTime] = React.useState(0);

    const [expiredTime, setExpiredTime] = React.useState(getLocalStorage(OTP_EXPIRED_TIME_KEY) || false);
    const [otpTimeoutTime, setOtpTimeoutTime] = React.useState(getLocalStorage(OTP_TIMEOUT_TARGET_TIME) || 0);

    const custData = useQuery(getCustomer, {
        context: {
            request: 'internal',
        },
        skip: !cusIsLogin,
        fetchPolicy: 'no-cache',
    });

    const LoginSchema = Yup.object().shape({
        username: Yup.string().email(t('validate:email:wrong')).required(t('validate:email:required')),
        password: Yup.string().required(t('validate:password:required')),
    });

    const LoginOtpSchema = Yup.object().shape({
        username: Yup.string().required(t('validate:phoneNumber:required')).matches(regexPhone, t('validate:phoneNumber:wrong')),
        otp: showOtp && Yup.number().required('Otp is required'),
    });

    const expired = storeConfig?.oauth_access_token_lifetime_customer
        ? new Date(Date.now() + parseInt(storeConfig?.oauth_access_token_lifetime_customer, 10) * 3600000)
        : expiredToken;

    const handleSend = (phoneNumber) => {
        const configSendOtp = {
            maxLength: otpConfig?.data?.otpConfig?.otp_length?.[0]?.length_otp_login || 4,
            maxTry: otpConfig?.data?.otpConfig?.otp_max_try?.[0]?.max_try_otp_login || 3,
            expired: otpConfig?.data?.otpConfig?.otp_expired_time?.[0]?.expired_time_otp_login || 60,
        };

        window.backdropLoader(true);
        actRequestOtpLogin({
            variables: {
                phoneNumber,
            },
        })
            .then(() => {
                setShowOtp(true);
                window.backdropLoader(false);
                setManySendOtp(manySendOtp + 1);
                // eslint-disable-next-line no-nested-ternary
                setTime(configSendOtp && configSendOtp.expired ? configSendOtp.expired : 60);

                setExpiredTime(false);
                setOtpTimeoutTime(new Date().getTime() + configSendOtp.expired * 1000);

                window.toastMessage({
                    open: true,
                    text: t('common:otp:sendSuccess'),
                    variant: 'success',
                });
            })
            .catch((e) => {
                window.backdropLoader(false);
                if (e.message === 'phone number is already Registered') {
                    window.toastMessage({
                        open: true,
                        text: `${t('common:otp:registerOtpFailed', { phoneNumber })}`,
                        variant: 'error',
                    });
                } else if (e.message === 'Max retries exceeded') {
                    window.toastMessage({
                        open: true,
                        text: `${t('common:otp:registerOtpTooManyRetries', { phoneNumber })}`,
                        variant: 'error',
                    });
                } else {
                    window.toastMessage({
                        open: true,
                        text: e.message.split(':')[1] || t('common:otp:sendFailed'),
                        variant: 'error',
                    });
                }
            });
    };

    const handleSubmit = (formOtp, variables) => {
        let getTokenCustomer;
        if (formOtp === 'otp') {
            getTokenCustomer = getCustomerTokenOtp;
        } else {
            getTokenCustomer = getCustomerToken;
        }
        setLoadingForm(true);
        setDisabled(true);
        window.backdropLoader(true);
        const sendData = (data) => {
            getTokenCustomer({
                variables: data,
            })
                .then(async (res) => {
                    let is_login = false;
                    let customerToken = null;
                    if (formOtp === 'otp') {
                        is_login = res.data.generateCustomerTokenCustom.token;
                        customerToken = res.data.generateCustomerTokenCustom.token;
                    } else {
                        is_login = !!res.data.generateCustomerToken.token;
                        customerToken = res.data.generateCustomerToken.token;
                    }
                    if (is_login) {
                        setLogin(1, expired);
                        await setIsLogin(1);
                        localStorage.setItem(`${customerTokenKey}`, customerToken);
                        Cookies.set(`${customerTokenKey}`, customerToken, { expires: expiredToken });
                    } else {
                        setDisabled(false);
                        setLoadingForm(false);
                        window.backdropLoader(false);
                        window.toastMessage({
                            open: true,
                            variant: 'error',
                            text: t('login:failed'),
                        });
                    }
                })
                .catch((e) => {
                    setDisabled(false);
                    setLoadingForm(false);
                    window.backdropLoader(false);
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: e.message.split(':')[0] || t('login:failed'),
                    });
                });
        };
        sendData(variables);
    };

    const formEmail = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            const variables = {
                username: values.username,
                password: values.password,
            };
            handleSubmit('password', variables);
        },
    });

    const formikOtp = useFormik({
        initialValues: {
            username: '',
            otp: '',
        },
        validateOnMount: true,
        validationSchema: LoginOtpSchema,
        onSubmit: (values) => {
            const variables = {
                username: values.username,
                otp: values.otp,
            };
            if (showOtp) {
                handleSubmit('otp', variables);
            } else {
                handleSend(values.username);
            }
        },
    });

    const switchType = () => {
        setLoginType(loginType === 'otp' ? 'default' : 'otp');
    };

    React.useEffect(() => {
        if (formikOtp.values.otp.length === 4 && !formikOtp.values.otp.includes(' ')) {
            window.backdropLoader(true);
            const vars = {
                username: formikOtp.values.username,
                otp: formikOtp.values.otp,
            };
            handleSubmit('otp', vars);
        }
    }, [formikOtp.values.otp]);

    /**
     * Handling Social Login
     */

    const signInOptions = [];
    const [firebaseLoaded, setFirebaseLoaded] = React.useState(false);
    const [google, setGoogle] = React.useState('');
    const [facebook, setFacebook] = React.useState('');

    let socialLoginMethodData = [];
    if (
        socialLoginMethod.data
        && socialLoginMethod.data.getSigninMethodSocialLogin
        && socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed
        && socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed !== ''
    ) {
        socialLoginMethodData = socialLoginMethod.data.getSigninMethodSocialLogin.signin_method_allowed.split(',');
    }

    if (publicRuntimeConfig && publicRuntimeConfig?.firebaseApiKey !== ''
        && firebase && firebase.auth && socialLoginMethodData && socialLoginMethodData.length > 0) {
        for (let idx = 0; idx < socialLoginMethodData.length; idx += 1) {
            const code = socialLoginMethodData[idx];
            if (code.match(/google/i) && firebase.auth.GoogleAuthProvider && firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
            }

            if (code.match(/facebook/i) && firebase.auth.FacebookAuthProvider && firebase.auth.FacebookAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
            }

            if (code.match(/twitter/i) && firebase.auth.TwitterAuthProvider && firebase.auth.TwitterAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.TwitterAuthProvider.PROVIDER_ID);
            }

            if (code.match(/github/i) && firebase.auth.GithubAuthProvider && firebase.auth.GithubAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.GithubAuthProvider.PROVIDER_ID);
            }

            if (code.match(/email/i) && firebase.auth.EmailAuthProvider && firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID);
            }
        }
    }

    const cachePrice = useReactiveVar(priceVar);

    let cartId = '';
    if (typeof window !== 'undefined') {
        cartId = getCartId();
    }

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions,
        callbacks: {
            signInSuccessWithAuthResult: () => false,
        },
    };

    React.useEffect(() => {
        setLocalStorage(OTP_EXPIRED_TIME_KEY, expiredTime);
    }, [expiredTime]);
    React.useEffect(() => {
        setLocalStorage(OTP_TIMEOUT_TARGET_TIME, otpTimeoutTime);
    }, [otpTimeoutTime]);

    // eslint-disable-next-line consistent-return
    const handleSocialLogin = () => {
        if (firebase.app()) {
            try {
                const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
                    if (firebase.auth().currentUser) {
                        const fullname = user.displayName.split(' ');
                        const firstName = fullname[0];
                        let lastName = '';
                        const { email } = user;
                        fullname.forEach((entry) => {
                            if (entry !== firstName) {
                                lastName += `${entry} `;
                            }
                        });
                        firebase
                            .auth()
                            .currentUser.getIdToken(true)
                            .then((_user) => {
                                window.backdropLoader(true);
                                actSocialLogin({
                                    variables: {
                                        email,
                                        socialtoken: _user,
                                        firstname: firstName,
                                        lastname: lastName,
                                    },
                                })
                                    .then(async () => {
                                        setLogin(1, expired);
                                        await setIsLogin(1);
                                        if (router.route.includes('/customer/account/create')) {
                                            router.push('/');
                                        } else {
                                            router.reload('/');
                                        }
                                    })
                                    .catch((e) => {
                                        window.backdropLoader(false);
                                        window.toastMessage({
                                            open: true,
                                            variant: 'error',
                                            text: e.message.split(':')[0] || t('login:failed'),
                                        });
                                    });
                            });
                    }
                });
                return () => unregisterAuthObserver();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const clickGoogle = () => {
        google[0].click();
        handleSocialLogin();
    };

    const clickFacebook = () => {
        facebook[0].click();
        handleSocialLogin();
    };

    React.useEffect(() => {
        if (publicRuntimeConfig && publicRuntimeConfig?.firebaseApiKey === '') {
            setFirebaseLoaded(false);
        } else {
            setFirebaseLoaded(true);
        }
    }, [firebaseLoaded]);

    React.useEffect(() => {
        setGoogle(document.getElementsByClassName('firebaseui-idp-google'));
        setFacebook(document.getElementsByClassName('firebaseui-idp-facebook'));
    }, []);

    React.useEffect(() => {
        if (cartData.data && custData.data && cartData.data.customerCart && cartData.data.customerCart && cartData.data.customerCart.id) {
            Cookies.set(custDataNameCookie, {
                email: custData.data.customer.email,
                firstname: custData.data.customer.firstname,
                lastname: custData.data.customer.lastname,
                customer_group: custData.data.customer.customer_group,
                phonenumber: custData.data.customer.phonenumber,
                is_phonenumber_valid: custData.data.customer.is_phonenumber_valid,
            });
            const isCustHasAddress = custData.data.customer.default_shipping;
            setCookies('is_cust_address', isCustHasAddress);
            const uid_product = getCookies('uid_product_compare');
            const custCartId = cartData.data.customerCart.id;
            if (uid_product) {
                mergeCompareProduct({
                    variables: {
                        uid: uid_product,
                    },
                })
                    .then((res) => {
                        setCookies('uid_product_compare', res.data.assignCompareListToCustomer.compare_list.uid);
                        client.writeQuery({
                            query: localCompare,
                            data: {
                                item_count: res.data.assignCompareListToCustomer.compare_list.item_count,
                                items: res.data.assignCompareListToCustomer.compare_list.items,
                            },
                        });
                    })
                    .catch(() => {
                        //
                    });
            }
            if (cartId === '' || !cartId) {
                setCartId(custCartId, expired);
                if (Object.keys(cachePrice).length > 0) {
                    priceVar({});
                }
                setDisabled(false);
                window.backdropLoader(false);
                window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
                router.push('/customer/account');
            } else if (cartId !== custCartId) {
                mergeCart({
                    variables: {
                        sourceCartId: cartId,
                        destionationCartId: custCartId,
                    },
                })
                    .then(async (res) => {
                        await setCartId(res.data.mergeCarts.id, expired);
                        await setDisabled(false);
                        window.backdropLoader(false);
                        window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
                        setTimeout(() => {
                            if (router.route.includes('/customer/account/create')) {
                                router.push('/');
                            } else {
                                router.reload('/');
                            }
                        }, 1500);
                    })
                    .catch(() => {});
            }
        }

        if (cartData.error || custData.error) {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                variant: 'error',
                text: t('login:failed'),
            });
        }
    }, [cartData, custData]);

    // Listen to the Firebase Auth state and set the local state.
    React.useEffect(() => {
        if (publicRuntimeConfig && publicRuntimeConfig?.firebaseApiKey !== '') {
            if (!firebase.apps.length) {
                firebase.initializeApp({
                    ...features.firebase.config,
                    apiKey: publicRuntimeConfig?.firebaseApiKey,
                });
            } else {
                firebase.app(); // if already initialized, use that one
            }
            try {
                const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
                    if (firebase.auth().currentUser) {
                        const fullname = user.displayName.split(' ');
                        const firstName = fullname[0];
                        let lastName = '';
                        const { email } = user;
                        fullname.forEach((entry) => {
                            // eslint-disable-next-line eqeqeq
                            if (entry != firstName) {
                                lastName += `${entry} `;
                            }
                        });
                        firebase
                            .auth()
                            .currentUser.getIdToken(true)
                            .then((u) => {
                                setDisabled(true);
                                window.backdropLoader(true);
                                actSocialLogin({
                                    variables: {
                                        email,
                                        socialtoken: u,
                                        firstname: firstName,
                                        lastname: lastName,
                                    },
                                })
                                    .then(async (res) => {
                                        setLogin(1, expired);
                                        const customerToken = res.data.generateCustomerTokenSocialLogin.token;
                                        localStorage.setItem(`${customerTokenKey}`, customerToken);
                                        await setIsLogin(1);
                                    })
                                    .catch((e) => {
                                        setDisabled(false);
                                        window.backdropLoader(false);
                                        window.toastMessage({
                                            open: true,
                                            variant: 'error',
                                            text: e.message.split(':')[0] || t('login:failed'),
                                        });
                                    });
                            });
                    }
                });
                return () => unregisterAuthObserver();
            } catch {
                return () => {};
            }
        }
        return () => {};
    }, []);

    return (
        <form action={loginType === 'default' ? formEmail.handleSubmit : null}>
            <div className="flex flex-col justify-center py-4 px-6">
                { loginType === 'default' ? (
                    <>
                        <TextField
                            placeholder="User"
                            name="username"
                            type="email"
                            leftIcon={<UserIcon />}
                            leftIconProps={{
                                className: cx(
                                    'w-[45px] h-[40px]',
                                    'text-neutral-800',
                                ),
                            }}
                            classWrapper="!py-0"
                            className="!mb-0"
                            inputProps={{
                                className: '!py-[4px]',
                            }}
                            value={formEmail.values.username}
                            onChange={formEmail.handleChange}
                            error={formEmail.errors.username}
                            errorMessage={(formEmail.errors.username) ? formEmail.errors.username : ''}
                        />
                        <TextField
                            placeholder="Password"
                            name="password"
                            type="password"
                            leftIcon={<LockIcon />}
                            leftIconProps={{
                                className: cx(
                                    'w-[45px] h-[40px]',
                                    'text-neutral-800',
                                ),
                            }}
                            classWrapper="!py-0"
                            className="!mb-0"
                            inputProps={{
                                className: '!py-[4px]',
                            }}
                            value={formEmail.values.password}
                            onChange={formEmail.handleChange}
                            error={formEmail.errors.password}
                            errorMessage={(formEmail.errors.password) ? formEmail.errors.password : ''}
                        />
                        <Button
                            variant="plain"
                            link="/customer/account/forgotpassword"
                            className="!px-0"
                        >
                            {t('common:header:forgotpassword')}
                        </Button>
                        <Button
                            className="!bg-[#fe0000] flex justify-center"
                            classNameText="text-center"
                            disabled={disabled || Object.keys(formEmail.errors).length > 0}
                            onClick={formEmail.handleSubmit}
                            type="submit"
                        >
                            {
                                t('common:header:login')
                            }
                        </Button>
                    </>
                ) : (
                    <>
                        <div className="mb-4">
                            <TextField
                                placeholder="Phone Number"
                                name="username"
                                leftIcon={<PhoneIcon />}
                                leftIconProps={{
                                    className: cx(
                                        'w-[45px] h-[40px]',
                                        'text-neutral-800',
                                    ),
                                }}
                                classWrapper="!py-0"
                                className="!mb-0"
                                inputProps={{
                                    className: '!py-[4px]',
                                }}
                                value={formikOtp.values.username}
                                onChange={
                                    (e) => {
                                        if (!showOtp) {
                                            formikOtp.handleChange(e);
                                        }
                                    }
                                }
                                error={formikOtp.errors.username}
                                errorMessage={(formikOtp.errors.username) ? formikOtp.errors.username : ''}
                            />
                        </div>
                        {
                            (showOtp && !expiredTime)
                                ? (
                                    <div className="flex flex-col gap-4">
                                        <OtpInput
                                            value={formikOtp.values.otp}
                                            valueLength={4}
                                            onChange={(val) => formikOtp.setFieldValue('otp', val)}
                                        />
                                        <CountdownTimer2 targetDate={otpTimeoutTime} onCompleted={() => setExpiredTime(true)} />
                                    </div>
                                ) : (
                                    <Button
                                        className="!bg-[#fe0000] flex justify-center"
                                        classNameText="text-center"
                                        disabled={disabled || Object.keys(formikOtp.errors).length > 0}
                                        onClick={formikOtp.handleSubmit}
                                    >
                                        {
                                            t('common:header:requestOtp')
                                        }
                                    </Button>
                                )
                        }
                    </>
                ) }

                <Typography className="text-center my-2">
                    {t('common:header:orsign')}
                </Typography>
                <Button
                    className="!bg-neutral-black flex justify-center h-[34px] items-center"
                    classNameText="text-center"
                    icon={loginType === 'otp' ? <PhoneIcon /> : <LockIcon />}
                    iconProps={{
                        className: 'w-[16px] h-[16px]',
                    }}
                    onClick={switchType}
                    type="button"
                >
                    {loginType === 'otp' ? t('common:header:password') : t('common:header:phone')}
                </Button>
                <div className="flex flex-row justify-between items-center my-2 gap-2">
                    {firebaseLoaded && firebase.app() && !socialLoginMethod?.loading && (
                        <>
                            <Button
                                className="!bg-neutral-black flex justify-center h-[34px] items-center w-full"
                                classNameText="text-center"
                                onClick={clickGoogle}
                                type="button"
                            >
                                {t('common:header:google')}
                            </Button>
                            <Button
                                className="!bg-neutral-black flex justify-center h-[34px] items-center w-full"
                                classNameText="text-center"
                                onClick={clickFacebook}
                                type="button"
                            >
                                {t('common:header:facebook')}
                            </Button>
                            <div className="social-login" hidden>

                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                            </div>
                        </>
                    )}
                </div>
                <Typography className="text-center font-normal">
                    {t('common:header:noaccount')}
                    <Link className="font-medium ml-1" href="/customer/account/create" prefetch={false}>
                        {t('common:header:signup')}
                    </Link>
                </Typography>
            </div>
        </form>
    );
};

export default PopupLogin;
