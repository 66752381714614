import React from 'react';
import cx from 'classnames';

const DateTimeDisplay = ({ value }) => {
    const formattedNumber = value.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    return (
        <div className={cx(
            'leading-5 p-3',
            'flex flex-col items-center',
        )}
        >
            <p className="m-0">{formattedNumber}</p>
        </div>
    );
};

export default DateTimeDisplay;
