/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable consistent-return */
import cx from 'classnames';

const OtpInput = (props) => {
    const { value, valueLength, onChange } = props;
    const RE_DIGIT = new RegExp(/^\d+$/);

    const valueItems = React.useMemo(() => {
        const valueArray = value.split('');
        const items = [];

        for (let i = 0; i < valueLength; i += 1) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [value, valueLength]);

    const focusToNextInput = (target) => {
        const { nextElementSibling } = target;

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    const focusToPrevInput = (target) => {
        const { previousElementSibling } = target;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnChange = (e, idx) => {
        const { target } = e;
        let targetValue = target.value;
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling;

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(target);

            const { nextElementSibling } = target;

            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e) => {
        const { target, key } = e;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value;

        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            // eslint-disable-next-line consistent-return
            return;
        }

        const { previousElementSibling } = target;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e) => {
        const { target } = e;

        const prevInputEl = target.previousElementSibling;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <div className={cx(
            'mt-4',
            'w-full flex justify-center',
            'gap-x-3',
        )}
        >
            {valueItems.map((digit, idx) => (
                <input
                    autoFocus={idx === 0}
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={valueLength}
                    className={cx(
                        'font-sans',
                        'w-11 h-11',
                        'border border-[#161616]',
                        'rounded-md',
                        'text-center text-lg font-bold leading-none',
                    )}
                    onChange={(e) => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                    value={digit}
                />
            ))}
        </div>
    );
};

export default OtpInput;
