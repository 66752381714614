import DateTimeDisplay from '@core_modules/theme/components/header/components/CountdownTimer/DateTimeDisplay';
import { useCountdown, useCountdown2 } from '@core_modules/theme/components/header/components/CountdownTimer/hooks/useCountdown';
import React from 'react';
import cx from 'classnames';
import Typography from '@common_typography';

const ShowCounter = ({ minutes, seconds }) => (
    <div className="p-3">
        <div className={cx(
            'flex items-center justify-center',
            'text-[1rem] leading-7 font-bold text-neutral-black',
            'p-2',
            'rounded-md',
        )}
        >
            <Typography variant="h3" align="center" type="bold" className="font-sans">
                OTP akan kadaluarsa dalam
            </Typography>
            <DateTimeDisplay value={minutes} />
            <p>:</p>
            <DateTimeDisplay value={seconds} />
        </div>
    </div>
);

const CountdownTimer = ({ targetDate, otpSent, setExpiredTime }) => {
    const [minutes, seconds] = useCountdown(targetDate, otpSent);

    if (minutes + seconds <= 0) {
        setExpiredTime(true);
        return null;
    }
    return <ShowCounter minutes={minutes} seconds={seconds} />;
};

export const CountdownTimer2 = ({ targetDate, onCompleted }) => {
    const [minutes, seconds] = useCountdown2(targetDate, onCompleted);
    return <ShowCounter minutes={minutes} seconds={seconds} />;
};

export default CountdownTimer;
