/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from '@common_image';
import Typography from '@common_typography';
// import config from '@config';

import Button from '@common_button';

import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import HeartIcon from '@heroicons/react/24/outline/HeartIcon';
// import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';
import LogoutIcon from '@heroicons/react/24/solid/ArrowRightEndOnRectangleIcon';

import Drawer from '@common_drawer';
import Tabs from '@common_tabs';
import useMediaQuery from '@core/hooks/useMediaQuery';
import PopupLogin from '@core_modules/theme/components/header/components/PopupLogin';
import { modules } from '@config';

const Autocomplete = dynamic(() => import('@core_modules/theme/components/header/components/autocomplete'), { ssr: true });
const Menu = dynamic(() => import('@core_modules/theme/components/header/components/HeaderA/mcategory'), { ssr: true });
const ProductCompareIcon = dynamic(() => import('@core_modules/catalog/plugins/ProductCompare'), { ssr: true });
const ShoppingBagIcon = dynamic(() => import('@plugin_shoppingbag'), { ssr: true });
const NotificationBell = dynamic(() => import('@plugin_notificationbell'), { ssr: true });
const UserInfo = dynamic(() => import('@core_modules/theme/components/header/components/HeaderA/adaptive/plugin/userinfo'), { ssr: false });
const BurgerMenuCategories = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/categories'), { ssr: false });
const BurgerMenuAccount = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/account'), { ssr: false });
const DesktopHeader = (props) => {
    const {
        //
        t,
        storeConfig,
        isLogin,
        customer,
        setValue,
        handleSearch,
        dataMenu,
        loadingMenu,
        handleLogout,
        deviceWidth,
        cmsMenu,
        ...other
    } = props;
    // const { modules } = config;
    const { isDesktop, isMobile, isTablet } = useMediaQuery();

    const logoDimensions = {
        width: storeConfig?.logo_width || (isDesktop ? 244 : 220),
        height: storeConfig?.logo_height || (isDesktop ? 80 : 70),
    };
    const logoAdditionalProps = {};
    if (!isMobile) {
        logoAdditionalProps.styleContainer = {
            width: `${logoDimensions.width}px`,
            height: `${logoDimensions.height}px`,
            paddingTop: 0,
        };
    }

    if (isTablet) {
        logoAdditionalProps.styleContainer = {
            width: `${150}px`,
            height: `${50}px`,
            paddingTop: 0,
        };
    }

    const [open, setOpen] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);
    const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);
    const [localOpen, setLocalOpen] = React.useState(false);
    const [isSearchShown] = React.useState(false);

    const handleClose = () => {
        setLocalOpen(false);
        setTimeout(() => {
            setOpenBurgerMenu(false);
        }, 500);
    };

    useEffect(() => {
        setLocalOpen(openBurgerMenu);
    }, [openBurgerMenu]);

    const filteredData = dataMenu?.categories?.items[0]?.children.filter((item) => item.include_in_menu !== 0);
    const burgerMenuData = [
        {
            title: 'Menu',
            content: dataMenu && <BurgerMenuCategories data={filteredData} cmsMenu={cmsMenu} />,
            type: 'react-component',
        },
        {
            title: 'Account',
            content: <BurgerMenuAccount isLogin={isLogin} handleLogout={handleLogout} {...other} />,
            type: 'react-component',
        },
    ];

    const PopoverContent = () => {
        return (
            <ul className={cx('my-account-list__wrapper')}>
                <li
                    key={0}
                    className={cx(
                        'my-account-list__item swift-account-list-account',
                        'py-2',
                        'px-2',
                        'text-left',
                        'hover:cursor-pointer',
                        'hover:bg-neutral-100',
                    )}
                >
                    <Link href="/customer/account">
                        <Typography className={cx('currency-list__text', 'text-neutral-700')}>My Account</Typography>
                    </Link>
                </li>
                <li
                    key={3}
                    className={cx(
                        'my-account-list__item swift-account-list-btn-logout',
                        'py-2',
                        'px-2',
                        'text-left',
                        'hover:cursor-pointer',
                        'hover:bg-neutral-100',
                    )}
                    onClick={() => handleLogout()}
                >
                    <Typography className={cx('currency-list__text', 'text-primary-700')}>Log Out</Typography>
                </li>
            </ul>
        );
    };

    const maxHeightToShow = 200;

    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const checkScrollTop = () => {
                if (open && window.pageYOffset > maxHeightToShow) {
                    setOpen(false);
                }
            };
            window.addEventListener('scroll', checkScrollTop);
            return () => window.removeEventListener('scroll', checkScrollTop);
        }
    }, [open]);

    return (
        <div
            className={cx(
                'desktop-header',
                'transition-transform',
                'delay-300',
                'duration-500',
                'ease-in-out',
                'shadow-md',
                'bg-focusred',
                'relative',
            )}
        >
            <div id="top-header" />
            <div className={cx('middle-header', 'tablet:border-b-[1.5px]', 'tablet:border-b-neutral-200', 'tablet:py-4')}>
                <div
                    className={cx(
                        'middle-header__wrapper',
                        'm-[0_auto]',
                        'flex',
                        'flex-row',
                        'justify-between',
                        'tablet:max-w-screen-tablet',
                        'desktop:gap-x-5',
                        'px-2',
                        'tablet:px-2',
                        'desktop:gap-x-6',
                        'desktop:max-w-screen-desktop',
                        'desktop:px-2',
                        'mobile:max-tablet:grid',
                        'mobile:max-tablet:grid-cols-[4fr_8fr_4fr]',
                        'mobile:max-tablet:max-w-[100%]',
                        'items-center',
                        'gap-5',
                        'h-[80px]',
                    )}
                >
                    {!isDesktop ? (
                        <div className={cx('middle-header-tablet__burger-menu', 'desktop:hidden')}>
                            <Button
                                className={cx(
                                    'my-2',
                                    '!p-0',
                                    '!mx-0',
                                    'hover:shadow-none',
                                    'focus:shadow-none',
                                    'active:shadow-none',
                                    'active:shadow-none',
                                    '!bg-[rgba(0,0,0,0)]',
                                )}
                                onClick={() => {
                                    setOpenBurgerMenu(true);
                                }}
                                icon={<Bars3Icon />}
                                iconProps={{ className: cx('text-neutral-white', 'w-[24px]', 'h-[24px]') }}
                                iconOnly
                                variant="tertiary"
                                classNameText={cx('!text-neutral-white')}
                            />
                            {dataMenu && openBurgerMenu && (
                                <Drawer
                                    open={localOpen}
                                    handleClose={handleClose}
                                    position="left"
                                    className={cx('mobile:max-tablet:w-[280px]', 'tablet:max-desktop:w-[396px]', 'desktop:w-[540px]', {
                                        'animate-hamburger-drawer-in': localOpen,
                                        'animate-hamburger-drawer-out': !localOpen,
                                    })}
                                    customButtonClose
                                    backdrop
                                >
                                    <Tabs
                                        data={burgerMenuData}
                                        tabHasContent
                                        tabWrapperClassName={cx('border-none')}
                                        tabTitleWrapperClassName={cx('grid', 'grid-cols-2')}
                                        tabTitleClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                        tabTitleActiveClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                        tabTitleListClassName={cx('bg-neutral-100')}
                                        tabTitleListActiveClassName={cx('bg-neutral-white', 'border-b-[1px]', 'border-b-neutral-400')}
                                        tabContentClassName={cx('!pt-0', 'h-full', 'overflow-y-auto', 'overflow-x-hidden')}
                                    />
                                </Drawer>
                            )}
                        </div>
                    ) : null}
                    <div
                        className={cx(
                            'middle-header__logo',
                            'desktop:basis-[272px]',
                            'tablet:flex',
                            'items-center',
                            'cursor-pointer',
                            'mobile:max-tablet:h-[52px]',
                            'mobile:max-tablet:w-[148px]',
                            'mobile:max-tablet:mt-2',
                            'mobile:max-tablet:m-[0_auto]',
                            'mobile:max-tablet:relative',
                        )}
                    >
                        <Link href="/">
                            <Image
                                priority
                                className="swift-header-middle__logo-link"
                                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                alt={storeConfig.default_title}
                                {...logoDimensions}
                                {...logoAdditionalProps}
                                storeConfig={storeConfig}
                            />
                        </Link>
                    </div>
                    <div className={cx('middle-header__search', 'flex flex-row justify-end desktop:justify-between items-center', 'w-full gap-8')}>
                        <div className={cx('mobile:max-tablet:hidden flex flex-col', 'justify-center desktop:justify-between', 'w-full h-[80px]')}>
                            <Autocomplete setValue={setValue} handleSearch={handleSearch} t={t} storeConfig={storeConfig} deviceWidth={deviceWidth} />
                            <div className="flex flex-row menu-category mobile:max-desktop:hidden">
                                <div className="xs:basis-full menu-middle">
                                    <nav className="swift-menu-wrapper" role="navigation">
                                        {loadingMenu ? <></> : <Menu t={t} data={dataMenu} cmsMenu={cmsMenu} storeConfig={storeConfig} />}
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className={cx('hidden desktop:flex flex-col justify-between gap-2', 'w-full max-w-[244px]')}>
                            <div className="flex flex-row justify-end gap-2 relative">
                                {!isLogin ? (
                                    <Button
                                        variant="plain"
                                        classNameText="!text-neutral-350"
                                        className="!bg-neutral-white h-[34px] p-0 flex !items-center min-w-[170px]"
                                        icon={<LogoutIcon className="h-[20] w-[20]" />}
                                        onClick={() => setOpen(!open)}
                                    >
                                        Login/Register
                                    </Button>
                                ) : (
                                    <div className="flex flex-row gap-2">
                                        <UserInfo
                                            t={t}
                                            isLogin={isLogin}
                                            customer={customer}
                                            open={openInfo}
                                            setOpen={setOpenInfo}
                                            PopoverContent={PopoverContent}
                                        />
                                        <Button
                                            variant="plain"
                                            classNameText="!text-neutral-350"
                                            className="!bg-neutral-white h-[34px] p-0 flex !items-center w-max"
                                            link="/sales/order/track"
                                        >
                                            {t('common:menu:trackingorder')}
                                        </Button>
                                    </div>
                                )}

                                {open && (
                                    <div
                                        className={cx(
                                            'absolute top-[98px] -right-40 transform -translate-x-1/2 text-neutral-800 rounded-lg shadow-lg',
                                            'bg-neutral-white w-max',
                                            'z-dialog',
                                        )}
                                    >
                                        <PopupLogin t={t} storeConfig={storeConfig} />
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-row justify-end gap-2">
                                {isLogin ? (
                                    <>
                                        <div
                                            id="header-wishlist-icon"
                                            className={cx('swift-action-shopping-bag', 'flex justify-center items-center pt-[7px]')}
                                        >
                                            <Link href="/wishlist" className={cx('cursor-pointer', 'group')}>
                                                <HeartIcon className="mt-1 text-neutral-white" width={24} height={24} />
                                            </Link>
                                        </div>
                                        <div id="header-notification-icon" className={cx('swift-action-shopping-bag')}>
                                            <NotificationBell withLink />
                                        </div>
                                    </>
                                ) : null}
                                {modules.productcompare.enabled && (
                                    <div id="header-product-compare-icon" className={cx('swift-action-product-compare')}>
                                        <ProductCompareIcon withLink isLogin={isLogin} />
                                    </div>
                                )}
                                <div id="header-shoppingBag-icon" className={cx('swift-action-shopping-bag')}>
                                    <ShoppingBagIcon withLink storeConfig={storeConfig} />
                                </div>
                            </div>
                        </div>
                        <div className="desktop:hidden flex flex-row justify-end gap-2">
                            <div id="header-shoppingBag-icon" className={cx('swift-action-shopping-bag')}>
                                <ShoppingBagIcon withLink storeConfig={storeConfig} />
                            </div>
                        </div>

                        {/* <div className={cx('middle-header__statusicon', 'desktop:grid', 'desktop:grid-cols-[5fr_6fr]')}>
                            <div
                                className={
                                    cx(
                                    'middle-header__statusicon__left-section',
                                    'flex',
                                    'flex-row',
                                    'gap-x-2',
                                    'mobile:max-tablet:justify-end',
                                )
                                }
                            >
                                <div className={cx('swift-action-notification', 'mobile:max-tablet:hidden')}>
                                    <NotificationBell withLink />
                                </div>
                                {modules.productcompare.enabled && (
                                    <div className={cx('swift-action-product-compare', 'mobile:max-desktop:hidden')}>
                                        <ProductCompareIcon withLink isLogin={isLogin} />
                                    </div>
                                )}
                                <div className="search-icon tablet:hidden">
                                    <Button
                                        className={cx(
                                        'mt-3',
                                        '!px-0',
                                        '!py-0',
                                        'hover:shadow-none',
                                        'focus:shadow-none',
                                        'active:shadow-none',
                                        'active:shadow-none',
                                    )}
                                        onClick={() => setIsSearchShown(!isSearchShown)}
                                        icon={<MagnifyingGlassIcon />}
                                        iconProps={{ className: cx('text-neutral-700', 'w-[20px]', 'h-[20px]') }}
                                        iconOnly
                                        variant="tertiary"
                                        classNameText={cx('!text-neutral-700')}
                                    />
                                </div>
                            </div>
                            <div className={cx('middle-header__statusicon__right-section relative', 'mobile:max-desktop:hidden')}>
                                <span
                                    className={cx(
                                    'border-l-[1.5px]',
                                    'border-l-neutral-200',
                                    'absolute',
                                    'left-0',
                                    'top-[50%]',
                                    'translate-y-[-50%]',
                                    'h-7',
                                )}
                                >
                                &nbsp;
                                </span>
                                <div
                                    className={cx(
                                    'middle-header__statusicon__right-section__account',
                                    'pl-2',
                                    'mt-3',
                                    'hover:cursor-pointer',
                                    'flex',
                                    'justify-start',
                                    'group',
                                )}
                                >
                                    <UserInfo
                                        t={t}
                                        isLogin={isLogin}
                                        customer={customer}
                                        open={open}
                                        setOpen={setOpen}
                                        PopoverContent={PopoverContent}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className={cx('bottom-header', 'tablet:max-w-screen-tablet', 'desktop:max-w-screen-desktop', 'm-[0_auto]', 'px-6')}>
                {isSearchShown && isMobile ? (
                    <div className={cx('bottom-header-mobile__search')}>
                        <Autocomplete setValue={setValue} handleSearch={handleSearch} t={t} storeConfig={storeConfig} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default DesktopHeader;
